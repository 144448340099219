import React from 'react'
import { Card,Typography } from "@ellucian/react-design-system/core";
import '../styles/app.scss'
import iconImage from'../assets/icons/search-icon.png';

function MainCard() {
  return (
    <Card className="main-card">
      <div style={{ display: 'flex', alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
        <div style={{marginBottom:'20px'}}><Typography >Please click a Data Model to view</Typography></div>
        <div style={{backgroundColor:'#E7DEF9FF',borderRadius:'50%'}}><img src={iconImage} alt='Search'/></div>
      </div>
      
  </Card>
  )
}

export default MainCard

