import React, { useEffect, useState } from 'react';
import { List, Typography } from '@ellucian/react-design-system/core';
import ListModelItem from './ListModelItem';
import { getDataModel } from '../services/extendService';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function formatResourceName(resourceName) {
  return resourceName.replace(/(-|_)/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase());
}

function ModelList({ searchText }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getDataModel();

        const formattedData = result.reduce((acc, item) => {
          const existingItem = acc.find((i) => i.resourceName === item.resourceName);

          if (existingItem) {
            existingItem.versions.push({
              version: item.version,
              isExtended: item.isExtended,
            });
          } else {
            acc.push({
              resourceName: item.resourceName,
              userCreated: item.userCreated,
              versions: [
                {
                  version: item.version,
                  isExtended: item.isExtended,
                },
              ],
            });
          }

          return acc;
        }, []);

        setData(formattedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filterData = () => {
      const searchWords = searchText.toLowerCase().split(' ');

      const filtered = data.filter((item) => {
        return searchWords.every((word) => item.resourceName.toLowerCase().includes(word));
      });

      setFilteredData(filtered);
    };

    filterData();
  }, [data, searchText]);

  const listItems = loading ? (
    <Skeleton style={{ borderRadius: '1rem', display: 'block', lineHeight: 1, padding: '0.5rem', marginBottom: '0.5rem' }} count={10} height={15} />
  ) : (
    filteredData.map((item, index) => <ListModelItem key={index} data={item} resourceName={formatResourceName(item.resourceName)} />)
  );

  return (
    <div id="Side_Container" style={{ margin: 0 }}>
      <div
        id="Model_List"
        style={{
          overflowY: 'scroll',
          overflowWrap: 'break-word',
          height: '60vh',
          padding: '0 10px',
        }}
      >
        {!loading && filteredData.length === 0 ? (
          <div style={{ backgroundColor: '#EFF7FF', borderTop: '0.125rem solid #51ABFF' }}>
            <Typography style={{ padding: '1rem', color: '#2874BB', fill: '#2874BB' }}>No data models found.</Typography>
          </div>
        ) : (
          <List>{listItems}</List>
        )}
      </div>
    </div>
  );
}

export default ModelList;
