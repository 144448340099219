import React, { useState } from 'react';
import { Card, TextField, Typography, IconButton } from '@ellucian/react-design-system/core';
import addIcon  from '../assets/icons/add.svg';
import '../styles/app.scss';
import ModelList from './ModelList';

function ModelCard() {
  const [searchText, setSearchText] = useState('');

  const handleChange = (event) => {
    const newSearchText = event.target.value;
    setSearchText(newSearchText);

  };

  return (
    <Card className="model-card">
      <div style={{ padding: '10px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h2">All Data Models</Typography>
          <IconButton>
            <img style={{ hight: '30px' ,width:'30px',color:'white'}} src={addIcon} alt='add'></img>
          </IconButton>
        </div>
        <TextField
          data-testid={'searchField'}
          id="searchField"
          type="search"
          margin="normal"
          placeholder={"Search..."}
          onChange={handleChange}
          value={searchText}
          fullWidth
        />
        <ModelList searchText={searchText} /> 
      </div>
    </Card>
  );
}

export default ModelCard;
