import config from '../configuration/config';

export const getDataModel = async () => {
  const url = `${config.baseUrl}/datamodels/versions`;

  try {
    // Get the token from local storage
    const ethosJwt = localStorage.getItem('ethosJwt');

    const response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${ethosJwt}`,
        'Content-Type': 'application/json', // Adjust content type as needed
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch data: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error.message);
    throw error; // You might want to handle the error further or propagate it up the call stack
  }
};
