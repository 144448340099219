import React from 'react';
import { ListItem, Divider, ListItemText, ListItemIcon } from '@ellucian/react-design-system/core';
import darkCubeLogo from '../assets/icons/dark-cube.svg';
import ellucianLogo from '../assets/icons/ellucian.svg';
import '../styles/app.scss'
function ListModelItem({ key, data, resourceName }) {
  const logoSrc = data.userCreated ? darkCubeLogo : ellucianLogo;

  return (
   <>
      <ListItem >
        <ListItemIcon>
          <img className='logo' src={logoSrc} alt="Logo" />
          <ListItemText primary={resourceName} style={{  marginLeft: '10px',cursor: "pointer",maxWidth:'30vh',fontSize:'14px',color:'black'}} />
        </ListItemIcon>
      </ListItem>
      <Divider style={{ marginLeft: '50px', marginTop: '0px', marginBottom: '0px' }} />
    </>
  );
}

export default ListModelItem;
