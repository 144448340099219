import React from 'react';
import { EDSApplication } from '@ellucian/react-design-system/core';
import "./styles/app.scss";
import MainCard from './components/MainCard';
import ModelCard from './components/ModelCard';
const App = () => (
  <div className='iframe-container'>
    <EDSApplication>
      <div className="card-container">
        <ModelCard />
        <MainCard />
      </div>
    </EDSApplication>
  </div>
);

export default App;
